*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mt-3 {
  margin-top: .75rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-4 {
  height: 1rem;
}

.h-8 {
  height: 2rem;
}

.h-96 {
  height: 24rem;
}

.h-\[2px\] {
  height: 2px;
}

.h-\[6vh\] {
  height: 6vh;
}

.h-screen {
  height: 100vh;
}

.w-1\/2 {
  width: 50%;
}

.w-4 {
  width: 1rem;
}

.w-8 {
  width: 2rem;
}

.w-full {
  width: 100%;
}

.flex-initial {
  flex: 0 auto;
}

.origin-left {
  transform-origin: 0;
}

.scale-x-0 {
  --tw-scale-x: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.list-disc {
  list-style-type: disc;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-slate-100 {
  --tw-border-opacity: 1;
  border-color: rgb(241 245 249 / var(--tw-border-opacity));
}

.bg-current {
  background-color: currentColor;
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.bg-knf-darkblue {
  --tw-bg-opacity: 1;
  background-color: rgb(37 60 117 / var(--tw-bg-opacity));
}

.bg-knf-light {
  --tw-bg-opacity: 1;
  background-color: rgb(249 245 250 / var(--tw-bg-opacity));
}

.bg-slate-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.pt-3 {
  padding-top: .75rem;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.leading-normal {
  line-height: 1.5;
}

.tracking-normal {
  letter-spacing: 0;
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-knf-darkblue {
  --tw-text-opacity: 1;
  color: rgb(37 60 117 / var(--tw-text-opacity));
}

.text-knf-light {
  --tw-text-opacity: 1;
  color: rgb(249 245 250 / var(--tw-text-opacity));
}

.text-knf-pink {
  --tw-text-opacity: 1;
  color: rgb(255 107 154 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

:root {
  font-family: Inter, sans-serif;
}

@supports (font-variation-settings: normal) {
  :root {
    font-optical-sizing: auto;
    font-family: InterVariable, sans-serif;
  }
}

@font-face {
  font-family: InterVariable;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("InterVariable.e6ec8b96.woff2") format("woff2");
}

@font-face {
  font-family: InterVariable;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("InterVariable-Italic.e0a5e81d.woff2") format("woff2");
}

html, body {
  margin: 0;
  padding: 0;
}

.table {
  border-collapse: separate;
  border-spacing: 5em;
}

.list-circle {
  list-style-type: circle;
}

.list-square {
  list-style-type: square;
}

.list-decimal-leading-zero {
  list-style-type: decimal-leading-zero;
}

.list-lower-roman {
  list-style-type: lower-roman;
}

.list-upper-roman {
  list-style-type: upper-roman;
}

.list-lower-greek {
  list-style-type: lower-greek;
}

.list-lower-latin {
  list-style-type: lower-latin;
}

.list-upper-latin {
  list-style-type: upper-latin;
}

.list-armenian {
  list-style-type: armenian;
}

.list-georgian {
  list-style-type: georgian;
}

.list-lower-alpha {
  list-style-type: lower-alpha;
}

.list-upper-alpha {
  list-style-type: upper-alpha;
}

.leaflet-container a {
  color: #253c75;
}

.leaflet-popup-content {
  margin: 7px 37px 7px 8px;
  font-size: 1.3em;
  line-height: 1.4;
}

.leaflet-control-geocoder-icon {
  background-color: #fff !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12.2 13l3.4 6.6c.6 1.1 2.5-.4 2-1.2l-4-6.2z'/%3E%3Ccircle cx='10.8' cy='8.9' r='3.9' fill='none' stroke='%23000' stroke-width='1.5'/%3E%3C/svg%3E") !important;
}

.marker-cluster-small {
  background-color: #40404180;
}

.marker-cluster-small div {
  background-color: #404041b3;
}

.marker-cluster-medium {
  background-color: #40404180;
}

.marker-cluster-medium div {
  background-color: #404041b3;
}

.marker-cluster-large {
  background-color: #40404180;
}

.marker-cluster-large div {
  background-color: #404041b3;
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  text-align: center;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  margin-left: 5px;
  font: 12px Helvetica Neue, Arial, Helvetica, sans-serif;
}

.marker-cluster span {
  color: #fff;
  line-height: 30px;
}

.leaflet-oldie .marker-cluster-small, .leaflet-oldie .marker-cluster-small div, .leaflet-oldie .marker-cluster-medium, .leaflet-oldie .marker-cluster-medium div, .leaflet-oldie .marker-cluster-large, .leaflet-oldie .marker-cluster-large div {
  background-color: #404041b3;
}

#map {
  height: calc(40vh - 2.5rem);
}

@media (width >= 768px) {
  #map, #filter {
    height: calc(100vh - 2.5rem);
  }
}

@media (width >= 640px) {
  .sm\:container {
    width: 100%;
  }

  @media (width >= 640px) {
    .sm\:container {
      max-width: 640px;
    }
  }

  @media (width >= 768px) {
    .sm\:container {
      max-width: 768px;
    }
  }

  @media (width >= 1024px) {
    .sm\:container {
      max-width: 1024px;
    }
  }

  @media (width >= 1280px) {
    .sm\:container {
      max-width: 1280px;
    }
  }

  @media (width >= 1536px) {
    .sm\:container {
      max-width: 1536px;
    }
  }
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:bottom-0:before {
  content: var(--tw-content);
  bottom: 0;
}

.before\:left-0:before {
  content: var(--tw-content);
  left: 0;
}

.before\:h-\[2px\]:before {
  content: var(--tw-content);
  height: 2px;
}

.before\:w-full:before {
  content: var(--tw-content);
  width: 100%;
}

.before\:origin-left:before {
  content: var(--tw-content);
  transform-origin: 0;
}

.before\:scale-x-0:before {
  content: var(--tw-content);
  --tw-scale-x: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:bg-current:before {
  content: var(--tw-content);
  background-color: currentColor;
}

.before\:transition-transform:before {
  content: var(--tw-content);
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.hover\:bg-knf-pink:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 107 154 / var(--tw-bg-opacity));
}

.hover\:text-blue-400:hover {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.hover\:text-blue-800:hover {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

.hover\:text-knf-light:hover {
  --tw-text-opacity: 1;
  color: rgb(249 245 250 / var(--tw-text-opacity));
}

.hover\:text-knf-pink:hover {
  --tw-text-opacity: 1;
  color: rgb(255 107 154 / var(--tw-text-opacity));
}

.hover\:before\:scale-x-100:hover:before {
  content: var(--tw-content);
  --tw-scale-x: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.focus\:bg-knf-pink:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 107 154 / var(--tw-bg-opacity));
}

.focus\:text-blue-400:focus {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.focus\:text-blue-800:focus {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

.focus\:text-knf-light:focus {
  --tw-text-opacity: 1;
  color: rgb(249 245 250 / var(--tw-text-opacity));
}

.focus\:text-knf-pink:focus {
  --tw-text-opacity: 1;
  color: rgb(255 107 154 / var(--tw-text-opacity));
}

.group:hover .group-hover\:scale-x-100 {
  --tw-scale-x: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (width >= 640px) {
  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:w-4\/12 {
    width: 33.3333%;
  }

  .sm\:w-8\/12 {
    width: 66.6667%;
  }

  .sm\:overflow-scroll {
    overflow: scroll;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width >= 768px) {
  .md\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .md\:mt-6 {
    margin-top: 1.5rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:h-\[4vh\] {
    height: 4vh;
  }

  .md\:w-5\/12 {
    width: 41.6667%;
  }

  .md\:w-7\/12 {
    width: 58.3333%;
  }

  .md\:border-t {
    border-top-width: 1px;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@media (width >= 1024px) {
  .lg\:mb-12 {
    margin-bottom: 3rem;
  }

  .lg\:mb-3 {
    margin-bottom: .75rem;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:w-3\/12 {
    width: 25%;
  }

  .lg\:w-9\/12 {
    width: 75%;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1280px) {
  .xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}
/*# sourceMappingURL=index.72bfc587.css.map */
